import React, { useEffect } from 'react';
import LPOverridable from '@launchpad/components/overrides/LPOverridable';
import ModalDialog from '@launchpad/components/modal/ModalDialog';
import AppRadioInput from '@launchpad/components/elements/form/AppRadioInput';
import AuthService from '@launchpad/logic/services/AuthService';
import CardModel from '@launchpad/modules/card/models/CardModel';
import LoadingHelper from '@launchpad/util/LoadingHelper';
import {
  AppButton,
  AppIcon,
  InputField,
  SwitchButton,
  useSelector
} from '@launchpad';
import { Trans } from 'react-i18next';
import i18n from '@launchpad/i18n';

const LockCard = props => {
  const { addressOne } = useSelector((state: any) => state.auth?.profile || {});
  const [lostStolenModal, setLostStolenModal] = React.useState(false);
  const [
    showRequestReplacementCardContent,
    setShowRequestReplacementCardContent
  ] = React.useState(false);
  const [reasonForReplace, setReasonForReplace] = React.useState('');
  const [addressConfirmation, setAddressConfirmation] = React.useState(
    addressOne
  );
  const { card } = props;

  useEffect(() => {
    if (lostStolenModal) {
      setAddressConfirmation(addressOne);
      setReasonForReplace('');
    }
  }, [lostStolenModal]);

  async function changeCardLockStatus(status: any) {
    const { card } = props;
    return await new Promise((resolve, reject) => {
      let timeoutId: NodeJS.Timeout;

      const handleAfterClose = () => {
        clearTimeout(timeoutId);
        resolve(true);
      };

      timeoutId = setTimeout(() => {
        // resolve if execute not finished in 8 seconds
        resolve(true);
      }, 8000);

      LoadingHelper.executeAction(
        () => {
          return new CardModel().freezePaymentDevice(card.id, status);
        },
        {
          successMessage: status
            ? i18n.t('Your card is locked now')
            : i18n.t('Your card is active now'),
          loadingMessage: status
            ? i18n.t('Locking card...')
            : i18n.t('Unlocking card...'),
          failureMessage: i18n.t('An error ocurred, please try again later'),
          manualClose: false,
          renderIcon: (state: string, params: any) => {
            if (state === 'loading') return null;
            return status ? (
              <AppIcon name="fa.lock" style={{ fontSize: 150 }} />
            ) : (
              <AppIcon name="fa.unlock-alt" style={{ fontSize: 150 }} />
            );
          },
          beforeClose: () => {
            AuthService.refreshAuth();
          },
          afterClose: handleAfterClose
        }
      );
    });
  }

  async function submitReplaceCard() {
    const { card } = props;
    // first lock card if lost or stolen:
    if (
      (reasonForReplace === 'lost' || reasonForReplace === 'stolen') &&
      !!card.locked === false
    ) {
      await changeCardLockStatus(true);
    }

    LoadingHelper.executeAction(
      () => {
        return new CardModel().replacePaymentDevice(
          card.id,
          reasonForReplace,
          addressConfirmation
        );
      },
      {
        successMessage: i18n.t('Your card has been successfully replaced'),
        loadingMessage: i18n.t('Replacing card...'),
        failureMessage: i18n.t('Already requested replacement for this card!'),
        manualClose: false,
        renderIcon: (state: string) => {
          if (state === 'loading') return null;
          return <AppIcon name="fa.credit-card" style={{ fontSize: 150 }} />;
        },
        beforeClose: () => {
          setShowRequestReplacementCardContent(false);
          setReasonForReplace('');
          AuthService.refreshAuth();
        }
      }
    );
  }

  function openLostStolenModal() {
    setLostStolenModal(true);
  }

  function closeLostStolenModal() {
    if (showRequestReplacementCardContent) {
      setShowRequestReplacementCardContent(false);
      setReasonForReplace('');
    } else {
      setLostStolenModal(false);
    }
  }

  function requestReplacementCardContent() {
    return (
      <div
        style={{ flex: 1 }}
        className="d-flex flex-column align-items-center justify-content-between text-center"
      >
        <h1 style={{ fontSize: 32 }}>
          <Trans>Please provide the reason for the replacement card</Trans>
        </h1>
        <h2 style={{ fontSize: 18, paddingTop: 20 }}>
          <Trans>Select one of reasons:</Trans>
        </h2>

        <div className="d-flex align-items-center justify-content-between py-4 w-75">
          <AppRadioInput
            name="reasonForReplace"
            labelText={i18n.t('Lost')}
            onChange={() => setReasonForReplace('lost')}
            value="lost"
            checked={reasonForReplace === 'lost'}
            fontSizeLabel={18}
          />
          <AppRadioInput
            name="reasonForReplace"
            labelText={i18n.t('Stolen')}
            onChange={() => setReasonForReplace('stolen')}
            value="stolen"
            checked={reasonForReplace === 'stolen'}
            fontSizeLabel={18}
          />
          <AppRadioInput
            name="reasonForReplace"
            labelText={i18n.t('Damaged')}
            onChange={() => setReasonForReplace('damaged')}
            value="damaged"
            checked={reasonForReplace === 'damaged'}
            fontSizeLabel={18}
          />
        </div>
        <InputField
          className="input-group"
          materialProps={{
            fullWidth: true
          }}
          label={i18n.t('Confirm Address')}
          name="addressConfirmation"
          type="text"
          placeholder={'Confirm address'}
          InputLabelProps={{
            shrink: true
          }}
          value={addressConfirmation}
          onInputText={e => setAddressConfirmation(e)}
        />

        <div className="py-4">
          <AppButton
            disabled={reasonForReplace === '' || addressConfirmation === ''}
            onClick={() => submitReplaceCard()}
          >
            <Trans>REPLACE CARD</Trans>
          </AppButton>
        </div>
      </div>
    );
  }

  return (
    <div
      className="row details-holder justify-content-center align-items-center"
      style={{ flexDirection: 'column' }}
    >
      <div className="top-line" />

      <div className="col-sm-4">
        <ul className="control-card">
          <li>
            {card.locked ? i18n.t('Unlock card') : i18n.t('Lock Card')}
            <SwitchButton
              isChecked={!!card.locked}
              onChange={(val: any) => changeCardLockStatus(val)}
            />
          </li>
        </ul>
      </div>
      <AppButton onClick={() => openLostStolenModal()}>
        <Trans>Report lost or stolen</Trans>
      </AppButton>

      <ModalDialog
        show={lostStolenModal}
        onClose={() => closeLostStolenModal()}
      >
        <div className="lock-card-modal">
          <div
            className="close-btn"
            style={{
              justifyContent: showRequestReplacementCardContent
                ? 'flex-start'
                : 'flex-end'
            }}
          >
            <AppIcon
              name={
                showRequestReplacementCardContent
                  ? 'fa.chevron-left'
                  : 'fa.times'
              }
              style={{
                fontSize: 24,
                cursor: 'pointer'
              }}
              onClick={() => closeLostStolenModal()}
            />
          </div>

          {!showRequestReplacementCardContent ? (
            <div
              style={{ flex: 1 }}
              className="d-flex flex-column align-items-center justify-content-between text-center"
            >
              <h1 style={{ fontSize: 36 }}>
                <Trans>Was your card lost or {'\n'} stolen?</Trans>
              </h1>
              <h2 style={{ fontSize: 18, paddingTop: 20 }}>
                <Trans>
                  If so, please lock your card as soon {'\n'} as possible and
                  contact us on this number
                </Trans>
              </h2>

              <div className="py-4">
                <AppButton
                  onClick={() => setShowRequestReplacementCardContent(true)}
                >
                  <Trans>REQUEST REPLACEMENT CARD</Trans>
                </AppButton>
              </div>

              <div
                style={{
                  minWidth: 150,
                  padding: '30px 0'
                }}
                className="d-flex align-items-center justify-content-between"
              >
                <p>
                  {card.locked ? i18n.t('Unlock card') : i18n.t('Lock Card')}
                </p>
                <SwitchButton
                  isChecked={!!card.locked}
                  onChange={(val: any) => changeCardLockStatus(val)}
                />
              </div>

              <div className="d-flex align-items-center justify-content-center pr-3 call-phone-wrapper">
                <AppIcon
                  name="fa.phone"
                  style={{ fontSize: 32 }}
                  onClick={() => closeLostStolenModal()}
                />
                <LPOverridable name="card.page.lockModalNumber">
                  <a href="tel:0202 0202 0202" className="report-number">
                    <strong>(0202 0202 0202)</strong>
                  </a>
                </LPOverridable>
              </div>
            </div>
          ) : (
            requestReplacementCardContent()
          )}
        </div>
      </ModalDialog>
    </div>
  );
};

export default LockCard;
